import { toast } from '@lionstep/ui';

// Api
import { getCitiesApi } from '../../../../api/location.api';
import {
  getCompanyApi,
  getCompanyPicturesApi,
  postCompanyPictureApi,
  deleteCompanyPictureApi,
  addCollaboratorMailingPermissionApi,
  removeCollaboratorMailingPermissionApi,
  putCompanyApi,
} from '../../../../api/company.api';
import { getJobsTitlesApi } from '../../../../api/job.api';
import {
  getCollaboratorsApi,
  postUserCollaboratorApi,
} from '../../../../api/user.api';

// Types
import * as types from '../types/company.types';

export const getCompany = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.GET_COMPANY_START });

    const { data } = await getCompanyApi({ id });

    dispatch({
      type: types.GET_COMPANY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: types.GET_COMPANY_FAIL });
    toast.error('Error fetching company data', {
      id: types.GET_COMPANY_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

export const updateCompany =
  ({ id, company }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.UPDATE_COMPANY_START });

      const { data } = await putCompanyApi({ id, data: company });

      dispatch({
        type: types.UPDATE_COMPANY_SUCCESS,
        payload: data.company,
      });
      toast.success('Company data successfully updated', {
        id: types.UPDATE_COMPANY_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: types.UPDATE_COMPANY_FAIL });
      toast.error('Error updating company data', {
        id: types.UPDATE_COMPANY_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const getCompanyPictures = () => async (dispatch) => {
  try {
    dispatch({ type: types.GET_COMPANY_PICTURES_START });

    const { data } = await getCompanyPicturesApi();

    dispatch({
      type: types.GET_COMPANY_PICTURES_SUCCESS,
      payload: data.pictures,
    });
  } catch (error) {
    dispatch({ type: types.GET_COMPANY_PICTURES_FAIL });
    toast.error('Error fetching company pictures', {
      id: types.GET_COMPANY_PICTURES_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

export const uploadCompanyPictures = (imageData) => async (dispatch) => {
  try {
    dispatch({ type: types.UPLOAD_COMPANY_PICTURES_START });

    const { data } = await postCompanyPictureApi({ data: imageData });

    dispatch({
      type: types.UPLOAD_COMPANY_PICTURES_SUCCESS,
      payload: data.pictures,
    });
  } catch (error) {
    dispatch({ type: types.UPLOAD_COMPANY_PICTURES_FAIL });
    toast.error('Error uploading company pictures', {
      id: types.UPLOAD_COMPANY_PICTURES_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

export const deleteCompanyPicture =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.DELETE_COMPANY_PICTURES_START });

      await deleteCompanyPictureApi({
        data: { picture_id: id },
      });

      dispatch({
        type: types.DELETE_COMPANY_PICTURES_SUCCESS,
        payload: { pictureId: id },
      });
    } catch (error) {
      dispatch({ type: types.DELETE_COMPANY_PICTURES_FAIL });
      toast.error('Error deleting company picture', {
        id: types.DELETE_COMPANY_PICTURES_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const getCollaborators = () => async (dispatch) => {
  try {
    dispatch({ type: types.GET_COLLABORATORS_START });

    const { data } = await getCollaboratorsApi();

    dispatch({
      type: types.GET_COLLABORATORS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: types.GET_COLLABORATORS_FAIL });
    toast.error('Error fetching collaborators', {
      id: types.GET_COLLABORATORS_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

export const addCollaborator =
  ({ email, firstName, lastName, language, role }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.ADD_COLLABORATOR_START });

      const { data } = await postUserCollaboratorApi({
        email,
        first_name: firstName,
        last_name: lastName,
        language,
        role,
      });

      dispatch({
        type: types.ADD_COLLABORATOR_SUCCESS,
        payload: data,
      });
      dispatch(getCollaborators());
    } catch (error) {
      dispatch({ type: types.ADD_COLLABORATOR_FAIL });
      toast.error('Error adding collaborator', {
        id: types.ADD_COLLABORATOR_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const addMailingPermissionToCollaborator =
  ({ job_id, user_id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.ADD_COLLABORATOR_MAILING_PERMISSION_START });

      await addCollaboratorMailingPermissionApi({ data: { job_id, user_id } });

      dispatch({
        type: types.ADD_COLLABORATOR_MAILING_PERMISSION_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: types.ADD_COLLABORATOR_MAILING_PERMISSION_FAIL });
      toast.error('Error adding mailing permission to a collaborator', {
        id: types.ADD_COLLABORATOR_MAILING_PERMISSION_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const removeMailingPermissionToCollaborator =
  ({ job_id, user_id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.REMOVE_COLLABORATOR_MAILING_PERMISSION_START });

      await removeCollaboratorMailingPermissionApi({
        data: { job_id, user_id },
      });

      dispatch({
        type: types.REMOVE_COLLABORATOR_MAILING_PERMISSION_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: types.REMOVE_COLLABORATOR_MAILING_PERMISSION_FAIL });
      toast.error('Error adding mailing permission to a collaborator', {
        id: types.REMOVE_COLLABORATOR_MAILING_PERMISSION_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const getActiveJobsTitles = () => async (dispatch) => {
  try {
    dispatch({ type: types.GET_ACTIVE_JOBS_TITLES_START });

    const { data } = await getJobsTitlesApi();

    dispatch({
      type: types.GET_ACTIVE_JOBS_TITLES_SUCCESS,
      payload: data.results,
    });
  } catch (error) {
    dispatch({ type: types.GET_ACTIVE_JOBS_TITLES_FAIL });
    toast.error('Error fetching active job titles', {
      id: types.GET_ACTIVE_JOBS_TITLES_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

export const getLocations =
  (value, language = 'en') =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GET_COMPANY_LOCATIONS_START,
      });

      const { data } = await getCitiesApi({
        params: {
          input: value,
          language,
        },
      });

      dispatch({
        type: types.GET_COMPANY_LOCATIONS_SUCCESS,
        payload: data.predictions,
      });
    } catch (error) {
      dispatch({ type: types.GET_COMPANY_LOCATIONS_FAIL });
      toast.error('Error fetching locations', {
        id: types.GET_COMPANY_LOCATIONS_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };
