import { toast } from '@lionstep/ui';

// Api
import {
  getDashboardJobsApi,
  deleteJobApi,
  updateJobStatusApi,
  sendJobStatusChangedEmailApi,
  postUserToJobApi,
  sendNewJobCollaboratorEmailApi,
  deleteUserFromJobApi,
} from '../../../../api/job.api';
import { postUserCollaboratorApi } from '../../../../api/user.api';

// Types
import * as types from '../types/dashboard.types';

// Actions
import {
  addMailingPermissionToCollaborator,
  removeMailingPermissionToCollaborator,
} from '../../../company/redux/actions';

// Config
import { ENV } from '../../../../config';

// Constants
import { JOB_ARCHIVED_STATUSES, PAGE_SIZE } from '../../dashboard.constants';

export const getJobs =
  ({ offset, archived, user = undefined }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.GET_JOBS_START });

      const { data: response } = await getDashboardJobsApi({
        params: {
          limit: PAGE_SIZE,
          offset,
          archived: archived === 'true' ? 'true' : 'false',
          user,
        },
      });

      dispatch({
        type: types.GET_JOBS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({ type: types.GET_JOBS_FAIL });
    }
  };

export const deleteJob =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.DELETE_JOB_START });

      await deleteJobApi({ id });

      dispatch({ type: types.DELETE_JOB_SUCCESS });
      toast.success('Job deleted', {
        id: types.DELETE_JOB_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: types.DELETE_JOB_FAIL });
      toast.error('Error deleting job', {
        id: types.DELETE_JOB_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

const sendJobArchivedStatusChangedEmail =
  ({ id, title, status, user }) =>
  async () => {
    try {
      const emailPayload = {
        newStatusField: status,
        jobTitle: title,
        jobId: id,
        company: user.company_name,
        companyId: user.company_id,
        userName: user.display_name,
        userEmail: user.email,
      };

      await sendJobStatusChangedEmailApi({ data: emailPayload });
    } catch (error) {
      toast.error('Error sending email for archived job', {
        id: 'SEND_ARCHIVED_EMAIL',
        statusCode: error?.response?.status,
      });
    }
  };

export const archiveJob =
  ({ id, title, user }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.ARCHIVE_JOB_START });

      await updateJobStatusApi({
        id,
        data: { status: JOB_ARCHIVED_STATUSES.archived },
      });

      if (ENV !== 'development') {
        dispatch(
          sendJobArchivedStatusChangedEmail({
            id,
            title,
            status: JOB_ARCHIVED_STATUSES.archived,
            user,
          }),
        );
      }

      dispatch({ type: types.ARCHIVE_JOB_SUCCESS });
      toast.success('Job archived', {
        id: types.ARCHIVE_JOB_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: types.ARCHIVE_JOB_FAIL });
      toast.error('Error archiving job', {
        id: types.ARCHIVE_JOB_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const publishJob =
  ({ id, title, user }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.PUBLISH_JOB_START });

      await updateJobStatusApi({
        id,
        data: { status: JOB_ARCHIVED_STATUSES.published },
      });

      if (ENV !== 'development') {
        dispatch(
          sendJobArchivedStatusChangedEmail({
            id,
            title,
            status: JOB_ARCHIVED_STATUSES.published,
            user,
          }),
        );
      }

      dispatch({ type: types.PUBLISH_JOB_SUCCESS });
      toast.success('Job published', {
        id: types.PUBLISH_JOB_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: types.PUBLISH_JOB_FAIL });
      toast.error('Error publishing job', {
        id: types.PUBLISH_JOB_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

/**
 * JOB ACTIONS MODAL
 */
export const openJobActionModal = ({ modalType, job }) => ({
  type: types.OPEN_JOB_ACTION_MODAL,
  payload: { modalType, job },
});

export const closeJobActionModal = () => ({
  type: types.CLOSE_JOB_ACTION_MODAL,
});

/**
 * JOB SHARE
 */
const sendNewJobCollaboratorEmail =
  ({ user, jobTitle }) =>
  async () => {
    try {
      const [firstName, lastName] = user.display_name.split(' ');
      const emailPayload = {
        email: user.email,
        language: user.language,
        jobTitle,
        firstName,
        lastName,
      };

      await sendNewJobCollaboratorEmailApi({ data: emailPayload });
    } catch (error) {
      toast.error('Error sending email for the new collaboration', {
        id: 'SEND_NEW_COLLABORATOR_EMAIL',
        statusCode: error?.response?.status,
      });
    }
  };

export const addJobCollaborator =
  ({ job, user, shouldSendEmail = true, shouldAddMailingPermission }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.ADD_JOB_COLLABORATOR_START });

      const { data } = await postUserToJobApi({
        jobId: job.id,
        userId: user.local_id,
      });

      dispatch({
        type: types.ADD_JOB_COLLABORATOR_SUCCESS,
        payload: { jobId: job.id, userIds: data },
      });

      if (shouldSendEmail) {
        dispatch(
          sendNewJobCollaboratorEmail({ user, jobTitle: job.opening_title }),
        );
      }

      if (shouldAddMailingPermission) {
        dispatch(
          addMailingPermissionToCollaborator({
            job_id: job.id,
            user_id: user.local_id,
          }),
        );
      }
    } catch (error) {
      dispatch({ type: types.ADD_JOB_COLLABORATOR_FAIL });
      toast.error('Error adding collaborator to the job', {
        id: types.ADD_JOB_COLLABORATOR_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const removeJobCollaborator =
  ({ jobId, userId }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.REMOVE_JOB_COLLABORATOR_START });

      const { data } = await deleteUserFromJobApi({ jobId, userId });

      dispatch({
        type: types.REMOVE_JOB_COLLABORATOR_SUCCESS,
        payload: { jobId, userIds: data },
      });

      dispatch(
        removeMailingPermissionToCollaborator({
          job_id: jobId,
          user_id: userId,
        }),
      );
    } catch (error) {
      dispatch({ type: types.REMOVE_JOB_COLLABORATOR_FAIL });
      toast.error('Error removing collaborator from the job', {
        id: types.REMOVE_JOB_COLLABORATOR_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const createCollaboratorAndShareJob =
  ({
    job,
    email,
    firstName,
    lastName,
    language,
    role,
    shouldAddMailingPermission,
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.CREATE_AND_ADD_JOB_COLLABORATOR_START });
      const { data } = await postUserCollaboratorApi({
        email,
        first_name: firstName,
        last_name: lastName,
        role,
        language,
      });

      dispatch(
        addJobCollaborator({
          job,
          user: data,
          shouldSendEmail: false,
          shouldAddMailingPermission,
        }),
      );
      dispatch({ type: types.CREATE_AND_ADD_JOB_COLLABORATOR_SUCCESS });
    } catch (error) {
      dispatch({ type: types.CREATE_AND_ADD_JOB_COLLABORATOR_FAIL });
      if (
        error.response?.status === 400 &&
        error.response?.data?.error_message?.email?.length
      ) {
        toast.error(error.response.data.error_message.email[0], {
          id: types.CREATE_AND_ADD_JOB_COLLABORATOR_FAIL,
          statusCode: error?.response?.status,
        });
      } else {
        toast.error('Error adding collaborator', {
          id: types.CREATE_AND_ADD_JOB_COLLABORATOR_FAIL,
          statusCode: error?.response?.status,
        });
      }
      throw error;
    }
  };

export const resetDashboardStore = () => ({
  type: types.RESET_DASHBOARD_STORE,
});
