import { toast } from '@lionstep/ui';

// Apis
import {
  getCreditsTotalApi,
  getCreditsHistoryApi,
  getCreditsPackagesApi,
  postCreditsRejectionRefundApi,
  postAddCreditsApi,
  postChargeCreditsApi,
  getCreditsPurchasesApi,
  putPurchaseExpirationApi,
} from '../../../../api/credits.api';

// Types
import * as types from '../types/credits.types';

export const getCreditsTotal = () => async (dispatch) => {
  try {
    dispatch({ type: types.GET_CREDITS_TOTAL_START });

    const { data } = await getCreditsTotalApi();

    dispatch({
      type: types.GET_CREDITS_TOTAL_SUCCESS,
      payload: data.credits,
    });
  } catch (error) {
    dispatch({ type: types.GET_CREDITS_TOTAL_FAIL });
    toast.error('Error fetching total credits', {
      id: types.GET_CREDITS_TOTAL_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

export const getCreditsHistory = () => async (dispatch) => {
  try {
    dispatch({ type: types.GET_CREDITS_HISTORY_START });

    const { data } = await getCreditsHistoryApi();

    dispatch({
      type: types.GET_CREDITS_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: types.GET_CREDITS_HISTORY_FAIL });
    toast.error('Error fetching credits history', {
      id: types.GET_CREDITS_HISTORY_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

export const getCreditsPackages = () => async (dispatch) => {
  try {
    dispatch({ type: types.GET_CREDITS_PACKAGES_START });

    const { data } = await getCreditsPackagesApi();

    dispatch({
      type: types.GET_CREDITS_PACKAGES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: types.GET_CREDITS_PACKAGES_FAIL });
    toast.error('Error fetching credits packages', {
      id: types.GET_CREDITS_PACKAGES_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

export const refundCreditForRejected =
  ({ jobId, candidateId }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.REFUND_CREDIT_START });

      const { data } = await postCreditsRejectionRefundApi({
        jobId,
        candidateId,
      });

      dispatch(getCreditsHistory());
      dispatch(getCreditsTotal());
      dispatch({
        type: types.REFUND_CREDIT_SUCCESS,
        payload: data,
      });
      toast.success('Credits refunded successfully', {
        id: types.REFUND_CREDIT_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: types.REFUND_CREDIT_FAIL });
      toast.error('Error refunding credit', {
        id: types.REFUND_CREDIT_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const getPurchases =
  ({ companyId }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.GET_CREDITS_PURCHASES_START });

      const { data } = await getCreditsPurchasesApi({ companyId });

      dispatch({ type: types.GET_CREDITS_PURCHASES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: types.GET_CREDITS_PURCHASES_FAIL });
      toast.error('Error geting purchases', {
        id: types.GET_CREDITS_PURCHASES_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const addCredits =
  ({ companyId, data, onSuccess }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.ADD_CREDITS_START });

      const { data: newSupply } = await postAddCreditsApi({ companyId, data });

      dispatch(getCreditsTotal());
      dispatch({
        type: types.ADD_CREDITS_SUCCESS,
        payload: newSupply,
      });

      if (onSuccess && typeof onSuccess === 'function') {
        onSuccess();
      }

      toast.success('Credits added successfully', {
        id: types.ADD_CREDITS_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: types.ADD_CREDITS_FAIL });
      toast.error('Error adding credits', {
        id: types.ADD_CREDITS_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const chargeCredits =
  ({
    job_title,
    comment,
    charged_amount,
    job_posting_id,
    spent_on,
    onSuccess,
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.CHARGE_CREDITS_START });

      await postChargeCreditsApi({
        data: {
          job_title,
          comment,
          charged_amount,
          job_posting_id,
          spent_on,
        },
      });

      await dispatch(getCreditsHistory());
      await dispatch(getCreditsTotal());
      dispatch({
        type: types.CHARGE_CREDITS_SUCCESS,
      });

      if (onSuccess && typeof onSuccess === 'function') {
        onSuccess();
      }

      toast.success('Credits charged successfully', {
        id: types.CHARGE_CREDITS_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: types.CHARGE_CREDITS_FAIL });
      toast.error('Error charging credits', {
        id: types.CHARGE_CREDITS_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const updatePurchaseExpirationDate =
  ({ companyId, supplyId, data, onSuccess }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.UPDATE_PURCHASE_EXPIRATION_START });

      const { data: newData } = await putPurchaseExpirationApi({
        companyId,
        supplyId,
        data,
      });

      dispatch({
        type: types.UPDATE_PURCHASE_EXPIRATION_SUCCESS,
        payload: newData,
      });

      if (onSuccess && typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {
      dispatch({ type: types.UPDATE_PURCHASE_EXPIRATION_FAIL });
      toast.error('Error changing expiration date', {
        id: types.UPDATE_PURCHASE_EXPIRATION_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };
