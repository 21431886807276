import { rubyAxios, pythonAxios, layer1Axios } from './http';

export const getCreditsTotalApi = () =>
  rubyAxios({
    method: 'get',
    url: '/credits.json',
  });

export const getCreditsHistoryApi = () =>
  rubyAxios({
    method: 'get',
    url: '/credits/history.json',
  });

export const getCreditsPackagesApi = () =>
  layer1Axios({
    method: 'get',
    url: '/v3/main/packages',
  });

export const postChargeCreditsApi = ({ data = {} } = {}) =>
  rubyAxios({
    method: 'post',
    url: '/credits/charge_services.json',
    data,
  });

export const postAddCreditsApi = ({ companyId, data = {} } = {}) =>
  layer1Axios({
    method: 'post',
    url: `/v3/main/companies/${companyId}/supplies`,
    data,
  });

export const postCreditsRejectionRefundApi = ({ jobId, candidateId } = {}) =>
  pythonAxios({
    method: 'post',
    url: `/jobs/${jobId}/${candidateId}/revert_charged_credit`,
  });

export const getCreditsPurchasesApi = ({ companyId }) =>
  layer1Axios({
    method: 'get',
    url: `/v3/main/companies/${companyId}/supplies`,
  });

export const putPurchaseExpirationApi = ({ companyId, supplyId, data } = {}) =>
  layer1Axios({
    method: 'patch',
    url: `/v3/main/companies/${companyId}/supplies/${supplyId}`,
    data,
  });
