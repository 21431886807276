import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Label, SelectV2, Button, Checkbox, toast } from '@lionstep/ui';

// Helper
import { collaboratorsOptionMapper } from '../../../../../dashboard.helper';

// Queries
import {
  useAddUserToJobMutation,
  useAddJobMailingPermissionsMutation,
} from '../../../../../../../queries/jobQueries';

// Styles
import styles from './inviteExistingCollaborator.module.scss';

const InviteExistingCollaborator = ({
  job,
  collaborators,
  collaboratorsNotOnJob,
}) => {
  const { t } = useTranslation();

  const [shouldAddMailingPermission, setShouldAddMailingPermission] =
    useState(true);
  const [selectedUserOption, setSelectedUserOption] = useState({
    label: '',
    value: null,
  });

  // Add User to Job
  const { mutateAsync: addUserToJob } = useAddUserToJobMutation({
    onError: (error) => {
      toast.error('There was an error while adding job user.', {
        id: 'POST_ADD_JOB_USER',
        statusCode: error?.response?.status,
      });
    },
  });

  // Add Job Mailing Permission
  const { mutateAsync: addJobMailingPermission } =
    useAddJobMailingPermissionsMutation({
      onError: (error) => {
        toast.error('There was an error while adding job mailing permission.', {
          id: 'POST_ADD_JOB_MAILING_PERMISSION',
          statusCode: error?.response?.status,
        });
      },
    });

  const handleOnAddCollaborator = async () => {
    if (selectedUserOption.value) {
      const selectedUser = collaborators.find(
        (user) => user.local_id === selectedUserOption.value,
      );

      await addUserToJob({
        jobId: job.id,
        userId: selectedUser.local_id,
      });

      if (shouldAddMailingPermission) {
        await addJobMailingPermission({
          jobId: job.id,
          userId: selectedUser.local_id,
        });
      }

      setSelectedUserOption({ label: '', value: null });
    }
  };

  return (
    <>
      <Grid.Row gutter={15} className={styles.inviteExistingForm}>
        <Grid.Col xs={24} sm={16} lg={10} xl={8}>
          <Label htmlFor="inviteCollaborators">Select collaborator</Label>
          <SelectV2
            name="inviteCollaborators"
            placeholder={t('invite_collaborators')}
            options={collaboratorsOptionMapper(collaboratorsNotOnJob)}
            value={selectedUserOption}
            onChange={setSelectedUserOption}
          />
        </Grid.Col>

        <Grid.Col xs={24} sm={8} lg={6} xl={4}>
          <Button
            className={styles.submitButton}
            disabled={!selectedUserOption.value}
            onClick={handleOnAddCollaborator}
          >
            {t('share_job')}
          </Button>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row className={styles.sendCollaboratorStatus}>
        <Grid.Col xs={24}>
          <Checkbox
            inputAlign
            name="userJobNotifications"
            checked={shouldAddMailingPermission}
            label={t('send_email_updates')}
            onChange={(e) => setShouldAddMailingPermission(e.target.checked)}
          />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

InviteExistingCollaborator.defaultProps = {
  job: {},
};

InviteExistingCollaborator.propTypes = {
  collaborators: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  collaboratorsNotOnJob: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  job: PropTypes.shape(),
};

export default InviteExistingCollaborator;
