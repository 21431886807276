import { toast } from '@lionstep/ui';

// Api
import {
  getJobApi,
  updateJobAccountManagerApi,
  updateJobPersonalityCheckApi,
  updateJobApi,
  createJobApi,
  updateExtraInfoApi,
  postUserToJobApi,
  postJobMailingPermissionApi,
  postQuestionsApi,
  getExtraInfoApi,
  sendNewJobEmailApi,
} from '../../../../api/job.api';
import {
  getLanguagesApi,
  getAllSkillsApi,
  getTitlesApi,
} from '../../../../api/es.api';
import { getCitiesApi } from '../../../../api/location.api';
import {
  deleteDocumentApi,
  uploadDocumentApi,
} from '../../../../api/upload.api';

// Types
import * as types from '../types/job.types';

// Actions
import { createVersion } from './jobVersion.actions';

// helpers
import { langShorthand } from '../../../../support';
import {
  calculateDefaultKillerQuestions,
  calculateDefaultOptionalQuestions,
  refreshJobDescriptionAfterUpdate,
} from './helper';

/**
 * JOB
 */
export const getJob = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.GET_JOB_START });

    const { data } = await getJobApi({ id });

    dispatch({
      type: types.GET_JOB_SUCCESS,
      payload: data.job,
    });
  } catch (error) {
    dispatch({ type: types.GET_JOB_FAIL });
    toast.error('Error fetching job data', {
      id: types.GET_JOB_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

const updateAccountManager = (accountManager, jobId) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_JOB_ACCOUNT_MANAGER_START });

    const { data } = await updateJobAccountManagerApi({
      id: jobId,
      accountManager,
    });

    dispatch({
      type: types.UPDATE_JOB_ACCOUNT_MANAGER_START_SUCCESS,
      payload: data.account_manager,
    });
  } catch (error) {
    dispatch({ type: types.UPDATE_JOB_ACCOUNT_MANAGER_START_FAIL });
    throw error;
  }
};

const updateJobPersonalityCheck =
  (personalityCheck, jobId) => async (dispatch) => {
    try {
      dispatch({ type: types.UPDATE_JOB_PERSONALITY_CHECK_START });

      const { data } = await updateJobPersonalityCheckApi({
        id: jobId,
        personalityCheck,
      });

      dispatch({
        type: types.UPDATE_JOB_PERSONALITY_CHECK_SUCCESS,
        payload: data.personality_check,
      });
    } catch (error) {
      dispatch({ type: types.UPDATE_JOB_PERSONALITY_CHECK_FAIL });
      throw error;
    }
  };

const updateJobAdminActions = (jobData, jobId) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_JOB_START });

    const { data } = await updateJobApi({ jobData, id: jobId });

    dispatch({ type: types.UPDATE_JOB_SUCCESS, payload: data.job });
  } catch (error) {
    dispatch({ type: types.UPDATE_JOB_FAIL });
    throw error;
  }
};

export const updateJobAdminInfo =
  ({ jobData, extraInfoData }, job) =>
  async (dispatch) => {
    try {
      // update account manager
      if (job.account_manager !== jobData.account_manager) {
        await dispatch(updateAccountManager(jobData.account_manager, job.id));
      }
      // update personality check
      if (job.personality_check !== jobData.personality_check) {
        await dispatch(
          updateJobPersonalityCheck(jobData.personality_check, job.id),
        );
      }
      // update job
      await dispatch(updateJobAdminActions(jobData, job.id));
      await updateExtraInfoApi({ id: job.id, data: extraInfoData });

      if (job.slug) {
        // to trigger Next re-render
        refreshJobDescriptionAfterUpdate(job.slug);
      }

      toast.success('Admin Data successfully updated', {
        id: 'ADMIN_DATA_SUCCESS',
      });
    } catch (error) {
      toast.error('Error updating Admin Data', {
        id: 'ADMIN_DATA_ERROR',
        statusCode: error?.response?.status,
      });
    }
  };

export const resetJob = () => ({
  type: types.RESET_JOB,
});

/**
 * EXTRA INFO
 */
export const getJobExtraInfo = (jobId) => async (dispatch) => {
  try {
    dispatch({ type: types.GET_EXTRA_INFO_START });

    const { data } = await getExtraInfoApi({ id: jobId });

    dispatch({
      type: types.GET_EXTRA_INFO_SUCCESS,
      payload: { ...data.extra_info, id: data.id },
    });
  } catch (error) {
    dispatch({ type: types.GET_EXTRA_INFO_FAIL });

    toast.error('Error loading extra info', {
      id: types.GET_EXTRA_INFO_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

export const updateExtraInfo =
  (jobId, extraInfo, slug = null) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.UPDATE_EXTRA_INFO_START });

      const { data } = await updateExtraInfoApi({ id: jobId, data: extraInfo });

      if (slug) {
        // to trigger Next re-render
        refreshJobDescriptionAfterUpdate(slug);
      }

      dispatch({
        type: types.UPDATE_EXTRA_INFO_SUCCESS,
        payload: data.extra_info,
      });

      toast.success('Changes were saved', {
        id: types.UPDATE_EXTRA_INFO_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: types.UPDATE_EXTRA_INFO_FAIL });
      toast.error("Error updating job's extra info", {
        id: types.UPDATE_EXTRA_INFO_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

const addJobUsersAndSetMailingPermissions =
  ({ jobId, user }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.SET_JOB_USERS_START });

      const userId = user.local_id;

      await postUserToJobApi({ jobId, userId });
      await postJobMailingPermissionApi({
        data: { job_id: jobId, user_id: userId },
      });

      dispatch({ type: types.SET_JOB_USERS_SUCCESS });
    } catch (error) {
      dispatch({ type: types.SET_JOB_USERS_FAIL });
      toast.error('Error setting job users', {
        id: types.SET_JOB_USERS_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

const createDefaultKillerQuestions = (job) => async (dispatch) => {
  try {
    const { id: jobId, approach } = job;
    const languageCode = langShorthand(job.job_language.mid);

    dispatch({ type: types.CREATE_DEFAULT_KILLER_QUESTIONS_START });

    const defaultKillerQuestions = calculateDefaultKillerQuestions({
      approach: approach.toLowerCase(),
      languageCode,
    });

    await postQuestionsApi({
      id: jobId,
      questionType: 'killer_questions',
      data: { questions: defaultKillerQuestions },
    });

    dispatch({ type: types.CREATE_DEFAULT_KILLER_QUESTIONS_SUCCESS });
  } catch (error) {
    dispatch({ type: types.CREATE_DEFAULT_KILLER_QUESTIONS_FAIL });
    toast.error('Error creating default killer questions for the job', {
      id: types.CREATE_DEFAULT_KILLER_QUESTIONS_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

const createDefaultOptionalQuestions = (job) => async (dispatch) => {
  try {
    const { id: jobId, approach } = job;
    const languageCode = langShorthand(job.job_language.mid);

    dispatch({ type: types.CREATE_DEFAULT_OPTIONAL_QUESTIONS_START });

    const defaultOptionalQuestions = calculateDefaultOptionalQuestions({
      approach: approach.toLowerCase(),
      languageCode,
    });

    await postQuestionsApi({
      id: jobId,
      questionType: 'optional_questions',
      data: { questions: defaultOptionalQuestions },
    });

    dispatch({ type: types.CREATE_DEFAULT_OPTIONAL_QUESTIONS_SUCCESS });
  } catch (error) {
    dispatch({ type: types.CREATE_DEFAULT_OPTIONAL_QUESTIONS_FAIL });
    toast.error('Error creating default optional questions for the job', {
      id: types.CREATE_DEFAULT_OPTIONAL_QUESTIONS_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

export const sendNewJobPublishedEmail =
  ({ jobTitle, user }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.SEND_NEW_JOB_PUBLISHED_EMAIL_START });

      const emailPayload = {
        userEmail: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        company: user.company_name,
        companyId: user.company_id,
        jobTitle,
      };

      await sendNewJobEmailApi({ data: emailPayload });

      dispatch({ type: types.SEND_NEW_JOB_PUBLISHED_EMAIL_SUCCESS });
    } catch (error) {
      dispatch({ type: types.SEND_NEW_JOB_PUBLISHED_EMAIL_FAIL });
      toast.error('Error sending email notification for the job', {
        id: types.SEND_NEW_JOB_PUBLISHED_EMAIL_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const createJob =
  ({ job, extraInfo, isPublishing = false, history, user }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.CREATE_JOB_START });
      const { data } = await createJobApi({ jobData: job });

      await dispatch(updateExtraInfo(data.job.id, extraInfo));

      dispatch(createVersion({ id: data.job.id }));

      dispatch(
        addJobUsersAndSetMailingPermissions({ jobId: data.job.id, user }),
      );

      if (isPublishing) {
        dispatch(
          sendNewJobPublishedEmail({ jobTitle: data.job.opening_title, user }),
        );
      }

      if (data.job.approach) {
        await dispatch(createDefaultKillerQuestions(data.job));
        await dispatch(createDefaultOptionalQuestions(data.job));
      }

      dispatch({
        type: types.CREATE_JOB_SUCCESS,
        payload: data.job,
      });
      history.push(`/edit-job/${data.job.id}/admin`);
    } catch (error) {
      dispatch({ type: types.CREATE_JOB_FAIL });
      toast.error('Error creating job', {
        id: types.CREATE_JOB_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const updateJob =
  ({ id, job, extraInfo, isPublishing, shouldRedirect, slug, history, user }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.UPDATE_JOB_START });
      const { data } = await updateJobApi({ id, jobData: job });

      await dispatch(updateExtraInfo(data.job.id, extraInfo));

      if (isPublishing) {
        dispatch(
          sendNewJobPublishedEmail({ jobTitle: data.job.opening_title, user }),
        );
      }

      dispatch({
        type: types.UPDATE_JOB_SUCCESS,
        payload: data.job,
      });

      if (slug) {
        // to trigger Next re-render
        refreshJobDescriptionAfterUpdate(slug);
      }

      if (shouldRedirect) {
        history.push(`/edit-job/${data.job.id}/admin`);
      }
    } catch (error) {
      dispatch({ type: types.UPDATE_JOB_FAIL });
      toast.error('Error updating job', {
        id: types.UPDATE_JOB_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

/**
 * TITLES
 */
export const getTitles =
  (value, language = 'en') =>
  async (dispatch) => {
    try {
      dispatch({ type: types.GET_TITLES_START });

      const { data } = await getTitlesApi({
        value,
        languageCode: language,
      });

      dispatch({
        type: types.GET_TITLES_SUCCESS,
        payload: data.results,
      });
    } catch (error) {
      dispatch({ type: types.GET_TITLES_FAIL });
      toast.error('Error fetching titles', {
        id: types.GET_TITLES_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const clearTitles = () => (dispatch) =>
  dispatch({ type: types.CLEAR_TITLES });

/**
 * SKILLS
 */
export const getSkills =
  (value, language = 'en', skillsType = 'mandatory') =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GET_SKILLS_START,
        payload: {
          isLoadingMandatorySkills: skillsType === 'mandatory',
          isLoadingOptionalSkills: skillsType === 'optional',
        },
      });

      const { data } = await getAllSkillsApi({
        value,
        languageCode: language,
      });

      dispatch({
        type: types.GET_SKILLS_SUCCESS,
        payload: data.results,
      });
    } catch (error) {
      dispatch({ type: types.GET_SKILLS_FAIL });
      toast.error('Error fetching skills', {
        id: types.GET_SKILLS_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const clearSkills = () => (dispatch) =>
  dispatch({ type: types.CLEAR_SKILLS });

/**
 * LOCATIONS
 */
export const getLocations =
  (value, language = 'en', locationsType = 'main') =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GET_LOCATIONS_START,
        payload: {
          isLoadingMainLocations: locationsType === 'main',
          isLoadingAdditionalLocations: locationsType === 'additional',
        },
      });

      const { data } = await getCitiesApi({
        params: {
          input: value,
          language,
        },
      });

      dispatch({
        type: types.GET_LOCATIONS_SUCCESS,
        payload: data.predictions,
      });
    } catch (error) {
      dispatch({ type: types.GET_LOCATIONS_FAIL });
      toast.error('Error fetching locations', {
        id: types.GET_LOCATIONS_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const clearLocations = () => (dispatch) =>
  dispatch({ type: types.CLEAR_LOCATIONS });

/**
 * LANGUAGES
 */
export const getLanguages =
  (value, language = 'en') =>
  async (dispatch) => {
    try {
      dispatch({ type: types.GET_LANGUAGES_START });

      const { data } = await getLanguagesApi({
        value,
        languageCode: language,
      });

      dispatch({
        type: types.GET_LANGUAGES_SUCCESS,
        payload: data.results,
      });
    } catch (error) {
      dispatch({ type: types.GET_LANGUAGES_FAIL });
      toast.error('Error fetching languages', {
        id: types.GET_LANGUAGES_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const clearLanguages = () => (dispatch) =>
  dispatch({ type: types.CLEAR_LANGUAGES });

/**
 * JOB FILE
 */
export const uploadJobFile = (formData) => async (dispatch) => {
  try {
    dispatch({ type: types.UPLOAD_JOB_FILE_START });

    const { data } = await uploadDocumentApi({ data: formData });

    dispatch({
      type: types.UPLOAD_JOB_FILE_SUCCESS,
      payload: data.file,
    });
  } catch (error) {
    dispatch({ type: types.UPLOAD_JOB_FILE_FAIL });
    toast.error('Error uploading job file', {
      id: types.UPLOAD_JOB_FILE_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

export const deleteJobFile = (filename) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_JOB_FILE_START });

    await deleteDocumentApi({ filename });
    dispatch({
      type: types.DELETE_JOB_FILE_SUCCESS,
    });
  } catch (error) {
    dispatch({ type: types.DELETE_JOB_FILE_FAIL });
    toast.error('Error deleting job file', {
      id: types.DELETE_JOB_FILE_FAIL,
      statusCode: error?.response?.status,
    });
  }
};
